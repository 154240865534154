import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'gps-tracker-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  @Input() title: string = '';
  @Input() subtitle = '';
  @Input() customCard: string = '';
  @Input() record: any = null;
  @Output() activeDeactive: EventEmitter<any> = new EventEmitter();

  onToggleClick(): void {
    this.activeDeactive.emit(this.record);
  }
}



