<mat-card class="gps-card" [ngClass]="customCard">
  @if (title || subtitle) {
  <mat-card-header>
    <div class="gps-card-title">
      @if (title) {
      <mat-card-title>{{ title }}</mat-card-title>
      }
      @if (subtitle) {
      <mat-card-subtitle>{{ subtitle }}</mat-card-subtitle>
      }
    </div>
    @if (title === "Update") {
    <div class="toggle-wrapper">
      <span class="status-label">Status :</span>
      <button mat-icon-button (click)="onToggleClick()" class="toggle-button" [class.active]="record?.status === 1"
        aria-label="Active Deactive Toggle">
        <span class="slider" [class.active]="record?.status === 1">
          <span class="toggle-mark">{{ record?.status === 1 ? '✔' : '✖' }}</span>
        </span>
      </button>
    </div>
    }
  </mat-card-header>
  }
  <mat-card-content>
    <ng-content></ng-content>
  </mat-card-content>
</mat-card>