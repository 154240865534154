import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'gps-tracker-button',
  standalone: false,
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss'
})
export class ButtonComponent {

  @Input() btnType: 'primary' | 'primaryWithoutBorder' | 'primaryOutline' | 'secondary' | 'secondaryWithoutBorder' | 'secondaryOutline' | 'warning' | 'warningOutline' | 'success' | 'grey' = 'primary';
  @Input() disabled = false;
  @Input() isSubmitted = false;
  @Input() type = 'button';
  @Input() id = '';
  @Input() name = '';
  @Input() buttonStyle: object = {};
  @Input() matTooltipValue: any = '';
  @Input() leftIcon = '';
  @Input() labelStyle: object = {};
  @Input() label: string = '';
  @Input() rightIcon = '';
  @Output() clickEvent: EventEmitter<any> = new EventEmitter();

  fillColor: any;

  onClickButton(event: any): void {
    this.clickEvent.emit(event);
  }

  itemToolTipText() {
    return this.matTooltipValue;
  }
}
