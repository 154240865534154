import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { LocalStorageService } from '../../services';
import { Router } from '@angular/router';
import { LocalStorage } from '../../enums';
@Component({
  selector: 'gps-tracker-side-nav',
  standalone: false,
  templateUrl: './side-nav.component.html',
  styleUrl: './side-nav.component.scss'
})
export class SideNavComponent implements OnInit {

  @Input() isMenuOpen = true;
  @Output() menuToggle: EventEmitter<any> = new EventEmitter();
  innerWidth: number = 0;

  menuData: any = [];
  filteredMenuData: any[] = [];
  applicationMenuId = 0;
  applicationSubMenuId = 0;

  constructor(private localStorageService: LocalStorageService, private router: Router) {
    this.menuData = JSON.parse(this.localStorageService.getData(LocalStorage.userMenu));
    this.applicationMenuId = parseInt(this.localStorageService.getData(LocalStorage.applicationMenuId));
    this.applicationSubMenuId = parseInt(this.localStorageService.getData(LocalStorage.applicationSubMenuId));
    if (this.applicationMenuId === 0 || isNaN(this.applicationMenuId)) {
      this.router.events.subscribe((event: any) => {
        if (event.url) {
          for (const key in this.menuData) {
            const element = this.menuData[key];
            if (element.menu_url === event.url) {
              this.applicationMenuId = element.application_menu_id;
              this.applicationSubMenuId = 0;
              break;
            }
            else if (element.screen_type === "DETAIL" && element.menu.length > 0) {
              for (const key in element.menu) {
                const subElement = element.menu[key];
                if (subElement.menu_url === event.url) {
                  this.applicationMenuId = element.application_menu_id;
                  this.applicationSubMenuId = subElement.application_sub_menu_id;
                  break;
                }
              }
            }
          }
        }
      });
    }
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.filteredMenuData = this.menuData.map((menuItem: any) => {
      if (menuItem.menu && Array.isArray(menuItem.menu)) {
        menuItem.menu = menuItem.menu.filter((subMenu: any) => subMenu.action_type > 0);
      }
      return menuItem.action_type > 0 || (menuItem.menu && menuItem.menu.length > 0) ? menuItem : null;
    }).filter((menuItem: any) => menuItem);
  }


  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  onHideShow(): void {
    this.menuToggle.emit(!this.isMenuOpen);
  }

  menuClick(menuItem: any): void {
    if (menuItem.screen_type === "DETAIL" && menuItem.menu.length > 0) {
      this.applicationMenuId = this.applicationMenuId === menuItem.application_menu_id ? "" : menuItem.application_menu_id;
    } else if (menuItem.action_type > 0) {
      this.openMenu(menuItem.application_menu_id, menuItem.menu_url, true);
    }
  }

  openMenu(mainMenuId: any, url: any, isMainMenu = false) {
    if (isMainMenu) {
      this.applicationMenuId = mainMenuId;
      this.applicationSubMenuId = 0;
      this.localStorageService.setData(LocalStorage.applicationMenuId, mainMenuId + "");
      this.localStorageService.setData(LocalStorage.applicationSubMenuId, "0");
    }
    else {
      this.applicationSubMenuId = mainMenuId;
      this.localStorageService.setData(LocalStorage.applicationMenuId, this.applicationMenuId + "");
      this.localStorageService.setData(LocalStorage.applicationSubMenuId, mainMenuId + "");
    }
    this.router.navigate([url]);
    if (innerWidth <= 991.98) {
      this.menuToggle.emit(false);

    }
  }
}
